<template>
  <div class="linkedin">
    <span>{{ code && success ? "Success" : "Error" }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: this.$route.query.code,
      success: this.$route.query.state == "ede1ba59587fdba1f738ec9701e2c632",
    };
  },
  mounted() {
    if (this.code && this.success) {
      window.opener.setLinkedinCode(this.code);
      window.close();
    }
  },
};
</script>